import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 876.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">

<path d="M2776 8149 c-371 -39 -689 -230 -892 -536 -42 -64 -110 -200 -120
-240 -4 -15 -10 -32 -14 -38 -4 -5 -18 -53 -31 -105 l-24 -95 0 -1645 c0
-1576 1 -1648 19 -1725 23 -97 39 -143 94 -265 60 -133 188 -294 317 -400 165
-135 349 -220 549 -255 131 -22 3203 -23 3342 0 356 57 688 303 856 632 49 94
76 169 111 297 l21 81 4 1560 c3 1145 0 1588 -8 1665 -41 362 -231 673 -535
877 -67 45 -223 123 -246 123 -8 0 -19 4 -24 9 -16 14 -183 50 -280 61 -110
12 -3024 11 -3139 -1z m1770 -429 c61 -5 153 -19 205 -29 94 -20 285 -76 304
-91 6 -4 27 -13 48 -19 20 -7 37 -17 37 -23 0 -6 -7 -8 -15 -4 -13 5 -14 2 -4
-15 15 -28 -1 -43 -32 -31 -87 34 -337 82 -531 102 -230 23 -530 2 -784 -55
-12 -3 -30 3 -43 14 l-22 19 28 11 28 11 -30 -6 c-47 -9 -18 12 50 35 56 20
109 34 232 61 120 27 352 36 529 20z m450 -851 c152 -27 299 -88 418 -173 61
-44 162 -141 193 -186 9 -14 20 -27 23 -30 18 -16 73 -112 100 -177 134 -322
95 -681 -106 -953 -125 -171 -298 -291 -509 -356 -86 -27 -102 -28 -327 -32
l-237 -3 -10 -52 c-6 -29 -20 -106 -31 -172 -12 -66 -25 -138 -30 -160 -4 -22
-13 -71 -20 -110 -19 -113 -79 -282 -109 -305 -4 -3 -15 -18 -25 -35 -32 -50
-108 -125 -171 -170 -142 -101 -261 -138 -440 -138 -114 0 -195 14 -195 33 0
5 4 30 10 57 14 70 41 211 51 263 4 25 26 140 49 255 23 116 44 224 47 240 2
17 11 66 20 110 8 44 23 125 33 180 71 385 85 439 137 535 56 104 144 185 243
225 110 43 142 47 533 52 364 5 379 6 409 27 51 35 71 70 72 125 1 54 -17 88
-68 125 -27 21 -41 21 -674 26 -714 6 -702 5 -837 73 -190 94 -326 247 -390
441 -28 82 -46 240 -33 276 7 20 13 20 911 20 595 0 924 -4 963 -11z m127
-3413 c3 -8 1 -17 -6 -20 -7 -2 -2 -5 11 -5 26 -1 20 -21 -7 -21 -9 0 -22 -5
-29 -12 -19 -19 -226 -88 -322 -107 -30 -6 -80 -16 -110 -23 -37 -9 -135 -13
-295 -13 -223 0 -250 2 -375 28 -219 45 -322 98 -245 127 18 7 59 4 138 -10
265 -45 586 -51 827 -14 102 15 361 71 369 80 10 10 39 3 44 -10z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
